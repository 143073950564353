import React from "react";
import { ChevronRight } from "react-feather";
import styled from "styled-components";

import { Container } from "../../components/Grid";
import {
  ScHelper,
  ScTitle,
  ScWhiteButton as ScWhiteButtonSrc,
  ScVerticalDiv,
} from "../../components/Shared";

const ScRoot = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  background: #0e0e13;

  @media screen and (min-width: 992px) {
    padding-top: 130px;
    padding-bottom: 250px;
  }
`;

const ScMain = styled.div`
  text-align: center;
`;

const ScWhiteButton = styled(ScWhiteButtonSrc)`
  padding-left: 16px;
  padding-right: 12px;

  svg {
    margin-bottom: 3px;
  }
`;

export const TransformYourIdea = () => {
  return (
    <ScRoot>
      <Container>
        <ScVerticalDiv />
        <ScMain>
          <ScTitle>Transform your trading.</ScTitle>
          <ScHelper>Take control today. Trade smarter.</ScHelper>
          <ScWhiteButton
            href="https://app.power.trade/signup"
            rel="noopener noreferrer"
            target="_blank"
          >
            Get started
            <ChevronRight size={20} />
          </ScWhiteButton>
        </ScMain>
      </Container>
    </ScRoot>
  );
};
