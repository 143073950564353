import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

import { Container } from "../../components/Grid";
import {
  ScHelper as ScHelperComp,
  ScSubTitle,
  ScTitle,
  ScItem as ScItemSrc,
} from "../../components/Shared";

const ScRoot = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: #0e0e13;

  @media screen and (min-width: 992px) {
    padding-top: 260px;
    padding-bottom: 290px;
  }
`;

const ScMain = styled.div`
  @media screen and (min-width: 992px) {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
`;
const ScContentPanel = styled.div`
  flex: 1;
`;
const ScImgPanel = styled(Img)`
  flex: 1;

  width: 100%;

  @media screen and (min-width: 992px) {
    width: 700px;
    margin-right: -200px;
    margin-top: -140px;
    margin-bottom: -140px;
  }
`;

const ScHelper = styled(ScHelperComp)`
  max-width: 500px;
  margin-left: 0;
`;

const ScFeatureList = styled.div`
  margin-top: 36px;
  margin-bottom: 36px;
`;

const ScItem = styled(ScItemSrc)`
  padding-right: 0;
  max-width: 420px;

  @media screen and (min-width: 992px) {
    margin-bottom: 24px;
  }
`;

export const InstancePriceQuotes = ({ data }: any) => {
  return (
    <ScRoot>
      <Container>
        <ScMain>
          <ScImgPanel fluid={data.quote.childImageSharp.fluid} loading="lazy" />
          <ScContentPanel>
            <ScSubTitle>RFQ - REQUEST-FOR-QUOTE</ScSubTitle>
            <ScTitle>Instant price quotes.</ScTitle>
            <ScHelper>
              Introducing RFQ, a powerful tool for traders to manage risk and
              obtain the best prices for large and complex trades.
            </ScHelper>

            <ScFeatureList>
              <ScItem>
                <svg
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.5 2.5v-2M10.5 2.5v-2M15.5 10.5h-2M15.5 5.5h-2M2.5 10.5h-2M2.5 5.5h-2M5.5 15.5v-2M10.5 15.5v-2M12.4 2.5H3.6a1.1 1.1 0 0 0-1.1 1.1v8.8a1.1 1.1 0 0 0 1.1 1.1h8.8a1.1 1.1 0 0 0 1.1-1.1V3.6a1.1 1.1 0 0 0-1.1-1.1Z"
                    stroke="#B4F002"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.5 4.5h-7v7h7v-7Z"
                    stroke="#B4F002"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <em>RFQ automation.</em>
                <span>Automated RFQ workflow. Anonymous two-way quotes.</span>
              </ScItem>
              <ScItem>
                <svg
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 5.729A5 5 0 1 0 10.271 9M9.5 2.5l2-2 1 3 3 1-2 2-3-1-1-3ZM5.5 10.5l9-9"
                    stroke="#B4F002"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <em>Strategies.</em>
                <span>
                  Execute complex trades with multiple options or futures
                  contracts in a single order.
                </span>
              </ScItem>
              <ScItem>
                <svg
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5.5h-6v8h6v-8ZM6.5 11.5h-6v4h6v-4ZM15.5.5h-6v5h6v-5ZM15.5 8.5h-6v7h6v-7Z"
                    stroke="#B4F002"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <em>RFQ stream.</em>
                <span>
                  Easily compare two-way quotes. View and manage all your quotes
                  in one place.
                </span>
              </ScItem>
            </ScFeatureList>
          </ScContentPanel>
        </ScMain>
      </Container>
    </ScRoot>
  );
};
