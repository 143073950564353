import * as React from "react";
import { graphql } from "gatsby";

import { Layout } from "../components/Layout";
import { Banner } from "../containers/home-page/banner";
import { TotalPackage } from "../containers/home-page/package";
import { InstancePriceQuotes } from "../containers/home-page/price-quotes";
import { UnleashThePower } from "../containers/home-page/unleash-the-power";
import { TransformYourIdea } from "../containers/home-page/transform-your-trading";

export const query = graphql`
  query getImage {
    banner: file(relativePath: { eq: "v5/banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 956, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    quote: file(relativePath: { eq: "v5/instance-price-quotes.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default function IndexPage({ data }: any) {
  return (
    <Layout>
      <Banner data={data} />
      <TotalPackage />
      <InstancePriceQuotes data={data} />
      <UnleashThePower />
      <TransformYourIdea />
    </Layout>
  );
}
