import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { ChevronRight } from "react-feather";

import { Container } from "../../components/Grid";
import {
  ScHelper,
  ScSubTitle,
  ScTitle,
  ScWhiteButton as ScWhiteButtonSrc,
} from "../../components/Shared";

const ScRoot = styled.div`
  margin-top: 6rem;
  margin-bottom: 6rem;

  @media screen and (min-width: 992px) {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
`;

const ScMain = styled.div`
  text-align: center;
`;

const ScWrapper = styled.div`
  margin-top: 48px;
`;

const ScCover = styled(Img)`
  margin-top: 4rem;
  margin-bottom: 4rem;
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 956px;

  @media screen and (min-width: 992px) {
    margin-top: 150px;
    margin-bottom: 150px;
  }
`;

const ScWhiteButton = styled(ScWhiteButtonSrc)`
  padding-left: 16px;
  padding-right: 12px;

  svg {
    margin-bottom: 3px;
  }
`;

export const Banner = ({ data }: any) => {
  return (
    <ScRoot>
      <Container>
        <ScMain>
          <ScSubTitle>OPTIONS · PERPETUALS · FUTURES</ScSubTitle>
          <ScTitle>Power up your&nbsp;trading.</ScTitle>
          <ScHelper>
            Unlock new trading opportunities with alt-coin options and
            perpetuals.
          </ScHelper>
          <ScWrapper>
            <ScWhiteButton
              href="https://app.power.trade/signup"
              rel="noopener noreferrer"
              target="_blank"
            >
              Get started
              <ChevronRight size={20} />
            </ScWhiteButton>
          </ScWrapper>
        </ScMain>

        <ScCover fluid={data.banner.childImageSharp.fluid} loading="eager" />
      </Container>
    </ScRoot>
  );
};
