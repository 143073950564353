import React from "react";
import styled from "styled-components";

import { Container } from "../../components/Grid";
import {
  ScHelper as ScHelperComp,
  ScSubTitle,
  ScTitle,
  ScItem as ScItemSrc,
  ScGrayButton,
  ScVerticalDiv,
  ScHorizontalDiv as ScHorizontalDivSrc,
} from "../../components/Shared";

const ScHorizontalDiv = styled(ScHorizontalDivSrc)`
  margin-bottom: 110px;
`;

const ScItem = styled(ScItemSrc)`
  margin-bottom: 24px;
`;

const ScRoot = styled.div`
  margin-top: 6rem;
  margin-bottom: 3rem;

  @media screen and (min-width: 992px) {
    margin-top: 100px;
    margin-bottom: 250px;
  }
`;

const ScMain = styled.div`
  text-align: center;
`;

const ScHelper = styled(ScHelperComp)`
  max-width: 500px;
`;

const ScFeatureList = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
  max-width: 880px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
  }
`;
const ScFeature = styled.div`
  max-width: 420px;
  background: #0e0e13;
  border-radius: 12px;
  margin-bottom: 24px;
  padding: 12px 24px;
  padding-bottom: 24px;
  flex: 1;

  @media screen and (min-width: 992px) {
    padding: 28px 44px;
    margin-left: 12px;
    margin-right: 12px;
  }
`;
const ScFeatureIcon = styled.div`
  background: #1e1e1e;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 24px;
    height: 24px;
  }
`;
const ScFeatureTitle = styled.h3`
  font-weight: 600;
  font-size: 20px;
  color: white;
  display: flex;
`;
const ScFeatureHelper = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #868f97;
  margin-bottom: 36px;
`;
const ScNewBadge = styled.div`
  left: 955px;
  top: 3840px;
  background: #b4f002;
  border: 1px solid #b4f002;
  font-size: 12px;
  line-height: 16px;
  height: 20px;
  color: #000000;
  padding: 2px 8px;
  margin-left: 6px;
  border-radius: 12px;
`;

export const UnleashThePower = () => {
  return (
    <ScRoot>
      <Container>
        <ScVerticalDiv />

        <ScMain>
          <ScSubTitle>ADVANCED TRADING TOOLS</ScSubTitle>
          <ScTitle>Unleash the power.</ScTitle>
          <ScHelper>
            Master your trades using Trade Ideas and Strategy Explorer. Discover
            expert insights and free trades.
          </ScHelper>
        </ScMain>

        <ScHorizontalDiv />

        <ScFeatureList>
          <ScFeature>
            <ScFeatureIcon>
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.5 9.5a8 8 0 0 0-16 0 7.997 7.997 0 0 0 5 7.413V19.5h6v-2.587a7.997 7.997 0 0 0 5-7.413ZM15.5 19.5v4h-6v-4"
                  stroke="#fff"
                  strokeMiterlimit="10"
                  strokeLinecap="square"
                />
                <path
                  d="M8.5 9.5a4 4 0 0 1 4-4"
                  stroke="#fff"
                  strokeMiterlimit="10"
                  strokeLinecap="square"
                />
              </svg>
            </ScFeatureIcon>
            <ScFeatureTitle>Trade Ideas</ScFeatureTitle>
            <ScFeatureHelper>
              Trade Ideas simplifies trade idea creation and discovery. You can
              compose ideas in the RFQ, save them and share them.
            </ScFeatureHelper>

            <div>
              <ScItem>
                <svg
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m15.5 15.5-3-3M6.5 12.5a6 6 0 1 0 0-12 6 6 0 0 0 0 12ZM6.5 3.5v6M9.5 6.5h-6"
                    stroke="#B4F002"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <em>Discover ideas.</em>
                <span>Easily explore market opportunities.</span>
              </ScItem>
              <ScItem>
                <svg
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M.5 11s3-4.5 7.5-4.5 7.5 4.5 7.5 4.5-3 4.5-7.5 4.5S.5 11 .5 11Z"
                    stroke="#B4F002"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 13.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM2 3.5a7.5 7.5 0 0 1 12 0"
                    stroke="#B4F002"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5 4.646a4.5 4.5 0 0 1 6 0"
                    stroke="#B4F002"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <em>Trade smarter.</em>
                <span>Save time by capturing your ideas, trade later.</span>
              </ScItem>
              <ScItem>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.5 5.5L10.5 0.5V3.5C7.344 3.5 3.5 5.354 3.5 10.5C5.059 7.977 8 7.5 10.5 7.5V10.5L15.5 5.5Z"
                    stroke="#B4F002"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.5 10.5V14C15.5 14.3978 15.342 14.7794 15.0607 15.0607C14.7794 15.342 14.3978 15.5 14 15.5H2C1.60218 15.5 1.22064 15.342 0.93934 15.0607C0.658035 14.7794 0.5 14.3978 0.5 14V4C0.5 3.60218 0.658035 3.22064 0.93934 2.93934C1.22064 2.65804 1.60218 2.5 2 2.5H3.5"
                    stroke="#B4F002"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <em>Collaborate easily.</em>
                <span>Share your trade ideas with friends and colleagues.</span>
              </ScItem>
            </div>

            <ScGrayButton disabled>Coming soon</ScGrayButton>
          </ScFeature>

          <ScFeature>
            <ScFeatureIcon>
              <svg
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 22.5c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11Z"
                  stroke="#fff"
                  strokeMiterlimit="10"
                  strokeLinecap="square"
                />
                <path
                  d="m16.5 6.5-3 7-7 3 3-7 7-3Z"
                  stroke="#fff"
                  strokeMiterlimit="10"
                  strokeLinecap="square"
                />
                <path d="m16.5 6.5-3 7-4-4 7-3Z" fill="#fff" />
              </svg>
            </ScFeatureIcon>
            <ScFeatureTitle>
              Strategy Explorer <ScNewBadge>New</ScNewBadge>
            </ScFeatureTitle>
            <ScFeatureHelper>
              Introducing the Strategy Explorer. Find zero-cost trade structures
              or trades that pay you up front.
            </ScFeatureHelper>

            <div>
              <ScItem>
                <svg
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m15.162.57.049 5.334-5.087-1.702"
                    stroke="#B4F002"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M.523 7.408A7.5 7.5 0 0 1 15.2 5.9M.838 15.431l-.049-5.335 5.087 1.702"
                    stroke="#B4F002"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.477 8.592A7.5 7.5 0 0 1 .8 10.1"
                    stroke="#B4F002"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <em>Limitless possibilities.</em>
                <span>Endless combinations easily identified.</span>
              </ScItem>
              <ScItem>
                <svg
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m6.218 8.406-4.517.975L.58 5.54 13.739.66l1.681 5.759L9.997 7.59"
                    stroke="#B4F002"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 9.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM6 11.056 3.5 15.5M8.98 9.242 12.5 15.5"
                    stroke="#B4F002"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <em>Find free trades.</em>
                <span>Generate upfront credits from some trades.</span>
              </ScItem>
              <ScItem>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5 15.5H2.5C1.395 15.5 0.5 14.605 0.5 13.5V2.5C0.5 1.395 1.395 0.5 2.5 0.5H13.5C14.605 0.5 15.5 1.395 15.5 2.5V13.5C15.5 14.605 14.605 15.5 13.5 15.5Z"
                    stroke="#B4F002"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 11.5C9.933 11.5 11.5 9.933 11.5 8C11.5 6.067 9.933 4.5 8 4.5C6.067 4.5 4.5 6.067 4.5 8C4.5 9.933 6.067 11.5 8 11.5Z"
                    stroke="#B4F002"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.5 3.5H4.5"
                    stroke="#B4F002"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <em>Trade instantly.</em>
                <span>Just click the trade button to create an RFQ.</span>
              </ScItem>
            </div>

            <ScGrayButton disabled>Coming soon</ScGrayButton>
          </ScFeature>
        </ScFeatureList>
      </Container>
    </ScRoot>
  );
};
